<div class="modal right-lightbox modal right fade  all-project-page modal-list" id="{{modalId}}"
  style="margin-top: -5px;" appAccessControl permission="schedule">
  <div class="modal-dialog modal-md">
    <div class="modal-content">

      <div class="modal-header-style pt-2 d-flex justify-content-between" appAccessControl permission="schedule">
        <div class="d-flex ml-2">
          <span class="cursor-pointer theme-text-color icon" data-dismiss="modal" (click)="reloadGroups()"> <i
              class="fa fa-arrow-right icon-style" aria-hidden="true"></i></span>
          <h5 id="myModalLabel2" class="ml-2">Schedule Details</h5>
        </div>
        <div class="icon mr-2"  appAccessControl permission="schedule.remove">
          <i class="fa fa-trash-o icon-style" style="cursor:pointer" aria-hidden="true" (click)="deleteSchedule()"
           ></i>
        </div>
      </div>

      <div class="tab-content dateFormat">
        <div id="detail" class="tab-pane fade show active in">

          <!-- Project name and Status -->
          <div class="row my-2 ml-3 mr-1 my-2">
            <div class="col-sm-8 p-0">
              <input matInput class="detail-title" style="font-size:16px;font-weight:500;"
                value="{{scheduleInfo[0]?._project[0]?.projectName}}" readonly>
              </div>

            <div class="col-sm-4"><input matInput value="{{scheduleStatus}}"
                class="scheduledStatus text-center font-weight-bold py-1"
                [style.background]="getBackground(scheduleStatus)" readonly [disabled]='true'>
            </div>

          </div>

          <!-- Client name and color -->
          <div class="d-flex my-2">

            <div class="ml-3">
              <span class="detail-heading">Client Name*</span><br>
              <input class="pt-1 schedule-detail" style="color:black; font-size: 14px" matInput
              value ="{{scheduleInfo[0]?.client?.userName ?? 'N/A'}}"
              readonly>
            </div>

            <div class="mr-2">
              <span class="detail-heading ml-5">Color</span>

                <br>
              <input class="ml-5 pt-2 schedule-color"
              value="{{scheduleInfo[0]?.rangeId?.color ?? '#D8D5D4'}}" matInput
              type="color" disabled>

            </div>
          </div>

        </div>

        <!-- job Name -->
        <div class="ml-3" (mouseenter)="editButton = true" (mouseleave)="editButton = false">
          <span class="detail-heading">Job Name</span> 
          <ng-container *ngIf="!schedulePermission">
            <span *ngIf="editButton === true" class="px-2 cursor-pointer theme-text-color"><i
                class="fa fa-pencil theme-text-color" aria-hidden="true"></i>
            </span> </ng-container>
            <br>
          <textarea class="cursor-pointer pt-2 jobName" (mouseenter)="editButton = true"
              [disabled]="schedulePermission" [rows]="getRows(scheduleInfo[0]?.rangeId?.name)"
              (mouseleave)="editButton = false" matInput
              value="{{scheduleInfo[0]?.rangeId?.name ? scheduleInfo[0]?.rangeId?.name : 'N/A' }}" 
              id="jobValue"
              (keydown.enter)="applyFilter($event)" maxlength="30"></textarea>
           
        </div>

        <div class="d-flex">
          <div class="col-sm-12 dateFormat" (mouseenter)="editDateButton = true" (mouseleave)="editDateButton = false">
              <span class="equipmentText" style="font-size: 12px;">Start - End Date*
              </span>

              <ng-container *ngIf="!schedulePermission">
                <span *ngIf="editDateButton === true" class="editDateButton px-2 cursor-pointer">
                  <i (click)="scheduleIndex()" class="fa fa-pencil theme-text-color" aria-hidden="true"></i>
                </span>
              </ng-container>

              <div class="row col-sm-12 pt-2" style="font-weight:500" >

                <div style="width:6rem" class="px-0" [style.pointer-events]="schedulePermission ? 'none' : 'auto'">
                  <input matInput style="font-size: 12px;" class="cursor-pointer detail-title" (click)="scheduleIndex()"
                    value="{{this.cos.getFormattedDate (scheduleInfo[0]?.rangeId?.jobDateRange[0]?.from)}}" readonly />
                </div>

                <div class="col-md-1 px-0 d-flex align-items-center justify-content-center">-</div>

                <div class="col-sm-3 px-0" [style.pointer-events]="schedulePermission ? 'none' : 'auto'">
                  <input class="cursor-pointer detail-title" matInput (click)="scheduleIndex()"
                    value="{{this.cos.getFormattedDate (scheduleInfo[0]?.rangeId?.jobDateRange[0]?.to)}}" readonly />
                </div>
              </div>
          </div>
        </div>

        <!-- Additional information -->
        <div class="mt-3 my-2">

          <div class="d-flex equipmentText justify-content-between w-100">
            <div class="ml-3" style="font-size:14px">Additional Information</div>
            <button class="mr-3 addButton btn btn-primary" (click)="addEmployees()" appAccessControl
              permission="schedule.write">Add <i class="cursor-pointer fa fa-plus" style="color:white"></i>
            </button>
          </div>


          <div class="d-flex flex-column equipmentText w-100 py-2">
            <ng-container *ngFor="let key of getKeys(uniqueDate); let j = index">
              <div class="d-flex align-items-center">
                <span style="font-size:14px" class=" ml-3 p-0 text-left text-decoration-underline font-weight-bold schedule-info">{{ getDayOfWeek(key) }}</span>
                <input 
                  class="ml-2 d-flex text-left text-decoration-underline font-weight-bold schedule-info" 
                  matInput 
                  value="{{this.cos.getFormattedDate(key)}}" 
                  readonly 
                  disabled="true">
              </div>
              <div class="mb-4 ml-3 w-100">
                <div class="d-flex" *ngIf="checkData(employees[j])">
                  <span style="color:black;">Employees: </span>
                  <div class="editableValues d-flex flex-wrap w-100 ml-2">
                    <span *ngFor="let item of employees[j];let k=index" style="position:relative">
                      <span style="width:fit-content" class="label d-flex mb-2 mr-2 py-1 px-3 text-center">{{item.name}}
                        <i (click)="deleteEmployee(item.id,key,'employees')" appAccessControl
                          permission="schedule.remove" class="fa fa-times-circle cursor-pointer icon"></i>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="d-flex my-2" *ngIf="checkData(groups[j])">
                  <span style="color:black">Groups: </span>
                  <div class="editableValues d-flex flex-wrap w-100 ml-4">
                    <span *ngFor="let item of groups[j];let k=index" style="position:relative">
                      <span style="width:fit-content"
                        class="label py-1 d-flex mr-2 mb-2 px-3 text-center">{{item.name}}<i
                          class="fa fa-times-circle cursor-pointer icon" (click)="deleteEmployee(item.id,key,'groups')"
                          appAccessControl permission="schedule.remove"></i>
                      </span>
                    </span>
                  </div>
                </div>

                <div class="d-flex my-2" *ngIf="checkData(groupEmployeesList[j])">
                  <span style="color:black ">Group Employees: </span>
                  <div class="editableValues d-flex flex-wrap w-100">
                    <span *ngFor="let item of groupEmployeesList[j];let k=index" style="position:relative">
                      <span style="width:fit-content"
                        class="label py-1 d-flex mr-2 mb-2 px-3 text-center">{{item.name}}<i
                          class="fa fa-times-circle cursor-pointer icon" (click)="deleteEmployee(item.id,key,'groupEmployees')"
                          appAccessControl permission="schedule.remove"></i>
                      </span>
                    </span>
                  </div>
              </div>

              <div class="d-flex" *ngIf="checkData(rigs[j])">
                <span style="color:black ; margin-right:40px;">Rigs: </span>
                <div class="editableValues d-flex flex-wrap w-100 ml-2">
                  <span *ngFor="let item of rigs[j]; let k = index" style="position:relative">
                    <span style="width:fit-content" class="label d-flex mb-2 mr-2 py-1 px-3 text-center"
                     [ngClass]="{'highlighted-item': item.isHighlighted}">
                      {{item.name}}
                      <i (click)="deleteEquipment(item.id, key, 'equipments')" appAccessControl permission="schedule.remove" class="fa fa-times-circle cursor-pointer icon"></i>
                    </span>
                  </span>
                </div>
              </div>

                <div class="d-flex" *ngIf="checkData(support[j])">
                  <span style="color:black">Equipments: </span>
                  <div class="editableValues d-flex flex-wrap w-100 ml-2">
                    <span *ngFor="let item of support[j]; let k = index" style="position:relative">
                      <span style="width:fit-content" class="label d-flex mb-2 mr-2 py-1 px-3 text-center"
                       [ngClass]="{'highlighted-item': item.isHighlighted}">
                        {{item.name}}
                        <i (click)="deleteEquipment(item.id, key, 'equipments')" appAccessControl permission="schedule.remove" class="fa fa-times-circle cursor-pointer icon"></i>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Notes section -->
        <div class="ml-3 " style="margin-bottom:5rem" (mouseenter)="editNote = true" (mouseleave)="editNote = false">
          <span class="detail-heading">Notes</span>
          <ng-container *ngIf="!schedulePermission">
            <span *ngIf="editNote === true" class="px-2 cursor-pointer theme-text-color"><i
                class="fa fa-pencil theme-text-color" aria-hidden="true"></i>
            </span> </ng-container>
          <textarea class="cursor-pointer pt-2 jobName w-100" matInput  (keydown.enter)="updateNotes($event)"
          [disabled]="schedulePermission" (mouseenter)="editNote = true"  (mouseleave)="editNote = false"
          [rows]="getRows(scheduleInfo[0]?.rangeId?.note)" placeholder="N/A"
          value="{{scheduleInfo[0]?.rangeId.note ? scheduleInfo[0]?.rangeId.note:''}}" maxlength="500"></textarea>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- modal to update date of schedule -->
<div class="modal common-modal" id="UpdateDate">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="border:1px solid black">
      <div class="modal-header">
        <h5 class="modal-title">Update Schedule Date</h5>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body padding-none mt-3">
        <form [formGroup]="updateDateForm">
          <div class="d-flex mt-2">
            <div class="col-sm-6">
              <mat-form-field (click)="startDate12.open()"  appearance="outline" class="d-block">
                <mat-label>Start Date*</mat-label>
                <input matInput [matDatepicker]="startDate12" formControlName="startDate" (dateChange)="dateFilter()"
                  style="border:none"  maxlength="10"
                  onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')">
                <mat-datepicker-toggle matSuffix [for]="startDate12"></mat-datepicker-toggle>
                <mat-datepicker #startDate12></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-sm-6">
              <mat-form-field  (click)="endDate12.open()"  appearance="outline" class="d-block">
                <mat-label>End Date*</mat-label>
                <input matInput [matDatepicker]="endDate12" formControlName="endDate"
                  [min]="updateDateForm.value.startDate" (dateChange)="dateFilter()" style="border:none"  maxlength="10"
                  onkeydown="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')">
                <mat-datepicker-toggle matSuffix [for]="endDate12"></mat-datepicker-toggle>
                <mat-datepicker #endDate12></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <button *ngIf=" equipmentDate.length===0 && employeeDate.length===0 &&groupsDate.length===0"
            class=" btn btn-primary float-right mx-3 my-2" (click)="submitDate()">
            Save
          </button>
        </form>
        <div *ngIf="employeeDate.length>0" class="mx-4">
          <p class="more-details mb-1" style="text-decoration:none;cursor:alias"> These Employees are already
            Scheduled
          </p>
          <table class="table table-bordered">
            <thead style="font-size:14px">
              <tr>
                <th style="width:8rem">Date</th>
                <th style="width:8rem">Employee</th>
                <th style="width:8rem">Shift</th>
                <th style="width:8rem">Schedule</th>
              </tr>
            </thead>
            <tbody style="font-size:14px">
              <tr *ngFor="let key of getKeys(uniqueEmpDate); let i = index">
                <td>{{ this.cos.getFormattedDate(key) }}</td>
                <td class="text-capitalize d-flex flex-column"><span
                    *ngFor="let item of uniqueEmpDate[key]">{{item.name}}</span></td>
                <td class="text-capitalize"><span class="d-flex"
                    *ngFor="let item of uniqueEmpDate[key]">{{this.cos.getCamelCaseValue (item.shift)}}</span></td>
                <td class="text-capitalize"><span class="d-flex" style="word-break:break-all"
                    *ngFor="let item of uniqueEmpDate[key]">{{item.schedule}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="equipmentDate.length>0" class="mx-4">
          <p class="more-details mb-1" style="text-decoration:none;cursor:alias"> These Equipments are already
            Scheduled
          </p>
          <table class="table table-bordered">
            <thead style="font-size:14px">
              <tr>
                <th style="width:8rem">Date</th>
                <th style="width:8rem">Equipment Name</th>
                <th style="width:8rem">Shift</th>
                <th style="width:8rem">Schedule</th>
              </tr>
            </thead>
            <tbody style="font-size:14px">
              <tr *ngFor="let key of getKeys(uniqueEquipDate); let i = index">
                <td>{{ this.cos.getFormattedDate(key) }}</td>
                <td><span *ngFor="let item of uniqueEquipDate[key]"
                    class="text-capitalize d-flex flex-column">{{item.name}}</span>
                </td>
                <td class="text-capitalize"><span class="d-flex"
                    *ngFor="let item of uniqueEquipDate[key]">{{this.cos.getCamelCaseValue(item.shift)}}</span></td>
                <td class="text-capitalize"><span class="d-flex" style="word-break:break-all"
                    *ngFor="let item of uniqueEquipDate[key]">{{item.schedule}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="groupsDate.length>0" class="mx-4">
          <p class="more-details mb-1" style="text-decoration:none;cursor:alias"> These Groups are already
            Scheduled
          </p>
          <table class="table table-bordered">
            <thead style="font-size:14px">
              <tr>
                <th style="width:8rem">Date</th>
                <th style="width:8rem">Groups Name</th>
                <th style="width:8rem">Shift</th>
                <th style="width:8rem">Schedule</th>
              </tr>
            </thead>
            <tbody style="font-size:14px">
              <tr *ngFor="let key of getKeys(uniqueGroupDate); let i = index">
                <td>{{ this.cos.getFormattedDate(key) }}</td>
                <td class="text-capitalize d-flex flex-column" style="border-bottom:none"><span
                    *ngFor="let item of uniqueGroupDate[key]">{{item.name}}</span></td>
                <td class="text-capitalize"><span class="d-flex"
                    *ngFor="let item of uniqueGroupDate[key]">{{this.cos.getCamelCaseValue(item.shift)}}</span></td>
                <td class="text-capitalize"><span class="d-flex" stye="word-break:break-all"
                    *ngFor="let item of uniqueGroupDate[key]">{{item.schedule}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="equipmentDate.length>0 || employeeDate.length>0 || groupsDate.length>0">
          <p class="checkValues mb-1 ml-4" style="text-decoration:none;cursor:alias;font-size:15px"> Note:</p>
          <ul class="mx-5">
            <li><b>Confirm</b> Button will remove the employees/group/equipments that are already scheduled in other
              schedule.
            </li>
            <li><b>Double Booking</b> Button will forcefully add the employees/group/equipments that are already
              scheduled in
              other schedule.</li>
          </ul>
          <button class="float-right mt-4 mr-4 strokeBtn" data-dismiss="modal" >
            Cancel
          </button>
          <button class="btn btn-primary float-right mt-4 mr-3" (click)="submitDate()">
            Confirm
          </button>
          <button class="btn btn-primary float-right mt-4 mr-3" (click)="doubleBooking()">
            Double Booking
          </button>


        </div>
      </div>
    </div>
  </div>
</div>

<app-schedule-emp-modal modalId="empModal" [modalValue]="modalValue" [type]="type">
</app-schedule-emp-modal>