import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Message } from '../interfaces/common-interface';
import { BranchList, BranchResponse, ClientList, ClientResponse, RepresentativeList, RepresentativeResponse } from '../interfaces/client';
@Injectable({
  providedIn: 'root'
})
export class ClientService {

  CLIENT = 'api/client';
  CLIENT_DETAIL = 'aggregation/contacts/contact-info';
  CLIENT_INFO = 'api/client/single';
  REPRESENTATIVE = 'api/representative/rep-list';
  CLIENT_BRANCH = 'api/client-branches';
  CLIENT_REP = 'api/representative';


  FILE_DOWNLOAD = 'api/client/excel-download';
  FILE_UPLOAD = 'api/client/excel-upload';

  CONTACT_DATA = 'api/representative/list';

  MERGE_CLIENT = 'api/client/merge';
  MERGE_BRANCH = 'api/client-branches/merge'

  constructor(private http: HttpClient) { }

  private emitChangeSource = new BehaviorSubject([]);

  callClient$ = this.emitChangeSource.asObservable();

  emitChange(change) {
    this.emitChangeSource.next(change);
  }

  getClient(search) {
    return this.http.get<ClientList>(`${environment.BASE_URL}${this.CLIENT}?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&search=${search.search ? search.search : ''}&sort=${search.sort}`);
  }

  getClients() {
    return this.http.get<ClientList>(`${environment.BASE_URL}${this.CLIENT}?pagination=false&sort=true`);
  }

  deleteClient(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };

    return this.http.delete<Message>(environment.BASE_URL + this.CLIENT, options);
  }

  addClient(data) {
    return this.http.post<ClientResponse>(environment.BASE_URL + this.CLIENT, data);
  }

  updateClient(data) {
    return this.http.put<ClientResponse>(environment.BASE_URL + this.CLIENT, data);
  }

  clientInfo(id) {
    return this.http.get<ClientResponse>(`${environment.BASE_URL}${this.CLIENT_INFO}?id=${id}`);
  }

  getRepresentative(id) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(`${environment.BASE_URL}${this.CONTACT_DATA}?client=${id}&sort=true`);
  }

  downloadFile() {
    return this.http.get<Message>(`${environment.BASE_URL}${this.FILE_DOWNLOAD}`);
  }

  uploadFile(data) {
    return this.http.post<Message>(environment.BASE_URL + this.FILE_UPLOAD, data);
  }
  
  mergeClients(data) {
    return this.http.post<ClientResponse>(environment.BASE_URL + this.MERGE_CLIENT, data);
  }

  /**
   * Branch Address's Services
   */

  getBranchList(search) {
    return this.http.get<BranchList>(`${environment.BASE_URL}${this.CLIENT_BRANCH}/list?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&search=${search.search}&client=${search.client}`);
  }


  getAllBranches(id: string) {
    return this.http.get<BranchList>(`${environment.BASE_URL}${this.CLIENT_BRANCH}/list?client=${id}`);
  }

  deleteBranches(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };

    return this.http.delete<Message>(environment.BASE_URL + this.CLIENT_BRANCH, options);
  }

  addBranch(data) {
    return this.http.post<BranchResponse>(environment.BASE_URL + this.CLIENT_BRANCH, data);
  }

  updateBranch(data) {
    return this.http.put<BranchResponse>(environment.BASE_URL + this.CLIENT_BRANCH, data);
  }

  branchDetails(id: string) {
    return this.http.get<BranchResponse>(`${environment.BASE_URL}${this.CLIENT_BRANCH}?id=${id}`);
  }


  /**
   * Branch Representatives's Services
   */

  getRepresentativeList(search) {
    return this.http.get<RepresentativeList>(`${environment.BASE_URL}${this.CLIENT_REP}/list?pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}&search=${search.search}&client=${search.client}&branch=${search.branch}`);
  }

  getRepresentatives(clientId) {
    return this.http.get<RepresentativeList>(`${environment.BASE_URL}${this.CLIENT_REP}/list?pagination=false&client=${clientId}`);
  }

  deleteRepresentative(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };

    return this.http.delete<Message>(environment.BASE_URL + this.CLIENT_REP, options);
  }

  addRepresentative(data) {
    return this.http.post<RepresentativeResponse>(environment.BASE_URL + this.CLIENT_REP, data);
  }

  updateRepresentative(data) {
    return this.http.put<RepresentativeResponse>(environment.BASE_URL + this.CLIENT_REP, data);
  }

  RepresentativeDetails(id: string) {
    return this.http.get<RepresentativeResponse>(`${environment.BASE_URL}${this.CLIENT_REP}?id=${id}`);
  }

  mergeBranches(data) {
    return this.http.post<ClientResponse>(environment.BASE_URL + this.MERGE_BRANCH, data);
  }

}
