<form class="row" [formGroup]="locationForm">
  <div class="{{setWidth()}}">
    <mat-form-field appearance="outline" class="d-block">
      <mat-label>{{isRequired ? locationData.name + '*' : locationData.name}}</mat-label>
      <input #map formControlName="address" matInput (onSelect)="getAddress($event)" appGooglePlaces
        placeholder="Address" type="text">
    </mat-form-field>
  </div>
  <ng-container  *ngIf="showCoordinate">
  <div class="{{setWidth()}}">
    <mat-form-field appearance="outline" class="d-block">
      <mat-label>{{isRequired ? 'Latitude*' : 'Latitude'}}</mat-label> 
      <input #map  (keypress)="validateInput($event)"  formControlName="lat" matInput min="0"  appGooglePlaces (change)="setAddress($event,'lat')"
      placeholder="Enter Latitude" type="text">
    </mat-form-field>
  </div>
  <div class="{{setWidth()}}">
    <mat-form-field appearance="outline" class="d-block">
      <mat-label>{{isRequired ? 'Longitude*' : 'Longitude'}}</mat-label> 
      <input #map matInput  formControlName="lng" (keypress)="validateInput($event)" appGooglePlaces (change)="setAddress($event,'lng')"
        placeholder="Enter Longitude" type="text">
    </mat-form-field>
  </div>
</ng-container>
</form>

<div class="position-relative mb-2" *ngIf="isMap">
  <i class="fa fa-map-marker marker" aria-hidden="true"></i>
  <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="true" [zoomControl]="true"
    (mapClick)="mapClicked($event)" (centerChange)="centerChange($event)">
  </agm-map>
</div>
