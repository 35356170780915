<div class="root-container">
  <div class="table-responsive my-2 tableEffect">
    <table class="table rounded-3">
      <thead class="thead bg-header fixedHeading pl-2 ad-text-size">
        <tr class="estimate-header">
          <th [attr.colspan]="columns.length">{{ tableHeading }}</th>
        </tr>
        <tr>
          <th *ngFor="let column of columns">
            {{ column.displayName }}
          </th>
        </tr>
      </thead>
      <tbody class="ad-text-size">
        <ng-container *ngFor="let row of dataList; let i = index">
          <tr [ngClass]="{'row-bg': row.expanded}">
            <th scope="row" class="permissions-container">
              <div class="permissions-cell" (click)="toggleRowVisibility(i)">
                <span class="arrow" [ngClass]="{'arrow-down': row.expanded, 'arrow-right': !row.expanded}"></span>
                <span class="permissions-text">{{ row.permissions }}</span>
              </div>
            </th>
            <td *ngFor="let field of columns.slice(1)">
              {{ row[field.name] || calculateTotal(row, field.name) }} 
            </td>
          </tr>

          <!-- Expanded row content -->
          <tr *ngIf="row.expanded">
            <td [attr.colspan]="columns.length">
              <table class="table mb-0">
                <tbody>
                  <tr *ngFor="let subRow of row.subRows" class="dynamic-row">
                    <th scope="row">
                      <span class="permissions-text">{{ subRow.permissions }}</span>
                    </th>

                    <!-- Show dropdown for mobeType in sub-rows -->
                    <ng-container *ngIf="mobe">
                      <ng-container *ngFor="let field of columns.slice(1)">
                        <td>
                          <!-- Show dropdown for mobeType only in sub-rows -->
                          <ng-container *ngIf="field.name === 'mobeType'">
                            <select [(ngModel)]="subRow[field.name]" (change)="onMobeTypeChange(row, subRow)" class="dropdown">
                              <option *ngFor="let option of mobeOptions" [value]="option.value">{{ option.label }}</option>
                            </select>
                          </ng-container>
                    
                          <!-- Disable flightCost and otherCost when DRIVE is selected for specific subRow -->
                          <ng-container *ngIf="field.name === 'flightCost' || field.name === 'otherCost'">
                            <input 
                              [disabled]="subRow.mobeType === 'DRIVE'" 
                              matInput 
                              class="ad-text-size text-center input-field" 
                              type="number" 
                              [(ngModel)]="subRow[field.name]" 
                              (input)="handleInputChange(row, field.name, $event.target.value, subRow._id)" 
                              maxlength="3" 
                            />
                          </ng-container>
                    
                          <!-- Display input for other fields -->
                          <ng-container *ngIf="field.name !== 'mobeType' && field.name !== 'flightCost' && field.name !== 'otherCost'">
                            <input 
                              min="0" 
                              matInput 
                              class="ad-text-size text-center input-field" 
                              type="number" 
                              [(ngModel)]="subRow[field.name]" 
                              (input)="handleInputChange(row, field.name, $event.target.value, subRow._id)" 
                              maxlength="3" 
                            />
                          </ng-container>
                        </td>                  
                      </ng-container>
                    </ng-container>                                      

                    <ng-container *ngIf="labor">
                      <ng-container *ngFor="let field of columns.slice(1)">
                        <td>
                          <span>{{ subRow[field.name] }}</span> <!-- Display value as plain text -->
                        </td>
                      </ng-container>
                    </ng-container>                  

                    <button *ngIf="saveShow" class="btn btn-primary float-right mx-1 my-2 margin-bottom" (click)="updateRecord('SAVE')">
                      Save
                    </button>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
