<div class="left-sidebar-pro">
    <nav id="sidebar" class="side-panel pr-2">
      <div class="sidebar-header">
        <a routerLink="/dashboard" class="ml-1">
          <div class="image-container">
            <img class="main-logo resized-logo" src="assets/images/authenticUpdatedLogo.png" alt="Logo" />
          </div>
        </a>
      </div>      

        <nav class="sidebar-nav left-sidebar-menu-pro">
          <ul class="metismenu scrollable-content" id="menu1">
            <li class="arrow-none" appAccessControl permission="dashboard">
              <a class="has-arrow" routerLink="/dashboard" routerLinkActive="active"
                routerLinkActiveOptions="{exact:true}"><i class="fa fa-dashboard" aria-hidden="true" style="margin-top: -5px;"></i><span
                  class="mini-click-non">Dashboard</span></a>
            </li>
            <li class="arrow-none" appAccessControl permission="project">
              <a class="has-arrow" routerLink="/project/projects" routerLinkActive="active"
                routerLinkActiveOptions="{exact:true}">
               <i class="fa fa-building" aria-hidden="true"></i>
                <span class="mini-click-non">Projects</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="my_task">
              <a class="has-arrow" ng-reflect-router-link="/general-task-list" routerLink="/general-task-list"
                routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <i class="fa fa-tasks" aria-hidden="true"></i>
                <span class="mini-click-non">General Tasks</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="vendor">
              <a class="has-arrow d-flex" ng-reflect-router-link="/vendor-list" routerLink="/vendor-list"
                routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <div class="material-symbols-outlined theme-text-color font-weight-bold icon">
                  storefront
                  </div>
                  <span class="mini-click-non">Vendor
                </span>
              </a>
            </li>

            <li class="arrow-none" appAccessControl permission="employee">
              <a class="has-arrow" ng-reflect-router-link="/users/employees" routerLink="/users/employees"
                routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <i class="fa fa-user-plus" aria-hidden="true"></i>
                <span class="mini-click-non">Employees</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="client">
              <a class="has-arrow" ng-reflect-router-link="/users/clients" routerLink="/users/clients"
                routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <i class="fa fa-user" aria-hidden="true"></i>
                <span class="mini-click-non">Clients</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="groups">
              <a class="has-arrow" ng-reflect-router-link="/group" routerLink="/group" routerLinkActive="active"
                routerLinkActiveOptions="{exact:true}">
                <i class="fa fa-users" aria-hidden="true"></i>
                <span class="mini-click-non">Groups</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="equipment">
              <a class="has-arrow" ng-reflect-router-link="/equipments" routerLink="/equipments"
                routerLinkActive="active" routerLinkActiveOptions="{exact: true}">
                <i class="fa fa-wrench"></i>
                <span class="mini-click-non">Equipment</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="company">
              <a class="has-arrow d-flex" ng-reflect-router-link="/company" routerLink="/company" routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <div class="material-symbols-outlined theme-text-color font-weight-bold icon">apartment </div>
                <span class="mini-click-non">Company</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="off_time_management">
              <a class="has-arrow" ng-reflect-router-link="/off-time-management" routerLink="/off-time-management"
                routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <i class="fa fa-clock-o" aria-hidden="true"></i>
                <span class="mini-click-non">Time Off</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="state_rule">
              <a class="has-arrow" ng-reflect-router-link="/state-rules" routerLink="/state-rules"
                routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <i class="fa fa-file-text" aria-hidden="true"></i>
                <span class="mini-click-non">State Rules</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="schedule">
              <a class="has-arrow" ng-reflect-router-link="/schedule" routerLink="/schedule" routerLinkActive="active"
                routerLinkActiveOptions="{exact:true}">
                <i class="fa fa-calendar" aria-hidden="true"></i>
                <span class="mini-click-non">Scheduler</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="chat">
              <a class="has-arrow" ng-reflect-router-link="/live-chat" routerLink="/live-chat" routerLinkActive="active"
                routerLinkActiveOptions="{exact:true}">
                <i class="fa fa-comments" aria-hidden="true"></i>
                <span class="mini-click-non">Live chat</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="bulletin">
              <a class="has-arrow" ng-reflect-router-link="/bulletin" routerLink="/bulletin" routerLinkActive="active"
                routerLinkActiveOptions="{exact:true}">
                <i class="fa fa-th-list" aria-hidden="true"></i>
                <span class="mini-click-non">Bulletin</span>
              </a>
            </li>
            <li class="arrow-none" appAccessControl permission="repair_maintenance">
              <a class="has-arrow d-flex" ng-reflect-router-link="/maintenance" routerLink="/maintenance"
                routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <div class="material-symbols-outlined theme-text-color font-weight-bold icon">
                  engineering </div>
                  <span class="mini-click-non">Repair & Maintenance</span>
              </a>
            </li>

            <li class="arrow-none" appAccessControl permission="perdiem">
              <a class="has-arrow" ng-reflect-router-link="/perdiem" routerLink="/perdiem" routerLinkActive="active"
                routerLinkActiveOptions="{exact:true}">
                <i class="fa fa-university" aria-hidden="true"></i>
                <span class="mini-click-non">Per Diem</span>
              </a>
            </li>

            <li class="arrow-none" appAccessControl permission="broadcast_notification">
              <a class="has-arrow d-flex" ng-reflect-router-link="/broadcast-notification" routerLink="/broadcast-notification"
                routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <div class="material-symbols-outlined font-weight-bold icon">
                  notifications
                </div><span class=" ml-1 mini-click-non">Broadcast Notification</span></a>
            </li>

            <li class="arrow-none" appAccessControl permission="acl">
              <a class="has-arrow d-flex" ng-reflect-router-link="/access-control" routerLink="/access-control"
                routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <div class="material-symbols-outlined font-weight-bold icon">
                  admin_panel_settings
                </div><span class=" ml-1 mini-click-non">Access Control</span></a>
            </li>

            <li class="arrow-none" appAccessControl permission="audit">
              <a class="has-arrow d-flex" ng-reflect-router-link="/audit-logs" routerLink="/audit-logs"
                routerLinkActive="active" routerLinkActiveOptions="{exact:true}">
                <div class="material-symbols-outlined theme-text-color font-weight-bold icon">lab_profile </div>
                <span class="mini-click-non">Audit Logs
                </span>
              </a>
            </li>

            <li class="theme-text-color cursor-pointer" appAccessControl permission="configuration"
              (click)="toggleMenuItem('configurations')">
              <a class="has-arrow">
                <span class="d-flex flex-row justify-content-between">
                  <span>
                    <i class="fa fa-cogs" aria-hidden="true"></i>
                    <span class="mini-click-non">Configurations</span>
                  </span>

                  <span class="theme-text-color mini-click-non">
                    <span *ngIf="expandedMenu !== 'configurations'" class="pl-3 material-icons dropdown-icon">
                      keyboard_arrow_right
                    </span>
                    <span *ngIf="expandedMenu === 'configurations'" class="pl-3 material-icons dropdown-icon">
                      keyboard_arrow_down
                    </span>
                  </span>
                </span>
              </a>
            </li>

            <!-- Sub menu of Configurations -->
            <nav *ngIf="expandedMenu === 'configurations'" class="sidebar-nav left-sidebar-menu-pro mini-click-non" style="overflow:inherit">
              <ul class="scrollable-content theme-text-color" *ngIf="expandedMenu === 'configurations'">
                <li>
                  <a routerLinkActive="active" data-toggle="tab" routerLink="/configuration/project">Project
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" routerLinkActive="active" routerLink="/configuration/estimates"
                    data-toggle="tab">Project Estimates
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" routerLinkActive="active" routerLink="/configuration/estimate-reviewer"
                    data-toggle="tab">Estimate Reviewer
                  </a>
                </li>
                <li>
                  <a routerLinkActive="active" data-toggle="tab" routerLink="/configuration/employee">Employee
                  </a>
                </li>
                <li>
                  <a routerLinkActive="active" data-toggle="tab" routerLink="/configuration/company">Company
                  </a>
                </li>
                <li>
                  <a routerLinkActive="active" data-toggle="tab" routerLink="/configuration/equipment">Equipment
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" routerLinkActive="active" routerLink="/configuration/bulletin-type"
                    data-toggle="tab">Bulletin Type
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" routerLinkActive="active" routerLink="/configuration/crew-assignment"
                    data-toggle="tab">R&M Assignment
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" routerLinkActive="active" routerLink="/configuration/vendor"
                    data-toggle="tab">Vendor
                  </a>
                </li>
                <li>
                  <a data-toggle="tab" routerLinkActive="active" routerLink="/configuration/general-setting"
                    data-toggle="tab">General Setting
                  </a>
                </li>
              </ul>
            </nav>
          </ul>
        </nav>

    </nav>
</div>