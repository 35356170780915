import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-estimates-dynamic-dropdown-table',
  templateUrl: './estimates-dynamic-dropdown-table.component.html',
  styleUrls: ['./estimates-dynamic-dropdown-table.component.scss']
})
export class EstimatesDynamicDropdownTableComponent {

  @Input() tableHeading = '';
  @Input() columns = [];
  @Input() dataList = [];
  @Input() labor = false;
  @Input() mobe = false;
  @Input() saveShow = false;
  @Input() isFetchUpdatedCost = true;
  @Input() mobeType: string; 
  @Input() mobeOptions: { value: string, label: string }[] = [];  // Input for mobe options
  @Input() isFlightCostDisabled: string;

  @Output() valueChange = new EventEmitter<{ row: any, fieldName: string, value: number, subRowId: string }>();
  @Output() save = new EventEmitter<string>();
  @Output() fetchUpdatedCost = new EventEmitter<void>();
  @Output() mobeTypeChange = new EventEmitter<string>();  // Emit change to parent

  intermediateMobe: number;
  dailyTravel: number;
  hotelJobSite: number;
  mobeDemobe: number;

  updateRecord(action: string): void {
    this.save.emit(action);
  }

  calculateTotal(row: any, fieldName: string): number {
    return row.subRows.reduce((sum, subRow) => {
      const value = Number(subRow[fieldName]);
      return sum + (isNaN(value) ? 0 : value);
    }, 0);
  }
  

  handleInputChange(row: any, fieldName: string, newValue: any, subRowId: string): void {
    const parsedValue = Number(newValue);
  
    // Find the specific sub-row and update its value
    const subRow = row.subRows.find((sub) => sub._id === subRowId);
    if (subRow && !isNaN(parsedValue)) {
      subRow[fieldName] = parsedValue; // Update the value in the sub-row
    }
  
    // Emit the value change event with subRowId
    this.valueChange.emit({ row, fieldName, value: parsedValue, subRowId });
  
    // Recalculate the total for the specific field and update the parent row
    this.updateTotal(row, fieldName);
  }  
   

  updateTotal(row: any, fieldName: string): void {
    // Calculate the sum of the specific field in the subRows
    const total = row.subRows.reduce((sum, subRow) => {
      const fieldValue = Number(subRow[fieldName]);
      return sum + (isNaN(fieldValue) ? 0 : fieldValue);
    }, 0);
  
    // Update the parent row's field value with the calculated total
    row[fieldName] = total;
  
  }  
  

  updateField(row: any, fieldName: string, value: number, subRowId: string): void {
    const parsedValue = Number(value);
    if (!isNaN(parsedValue)) {
      // Emit the value change event to the parent component, including subRowId
      this.valueChange.emit({ row, fieldName, value: parsedValue, subRowId });
    }
  }  
  
  toggleRowVisibility(index: number): void {
    this.dataList[index].expanded = !this.dataList[index].expanded;
  }

  onFetchUpdatedCost(): void {
    this.fetchUpdatedCost.emit();
  }

  // Method to handle mobeType change in sub-rows
  onMobeTypeChange(row: any, subRow: any): void {
    this.mobeTypeChange.emit(subRow.mobeType);
    if (subRow.mobeType === 'DRIVE') {
      subRow.flightCost = 0; 
      subRow.otherCost = 0; 
      // Disable flight cost and other cost fields for DRIVE
      subRow.disableFlightCost = true;
      subRow.disableOtherCost = true;
    } else if (subRow.mobeType === 'FLY'){
      // Enable flight cost and other cost fields for FLY
      subRow.disableFlightCost = false;
      subRow.disableOtherCost = false;
    }
  }  
}