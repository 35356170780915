import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

declare let google: any;  // Declare Google Maps to avoid any type error.

@Component({
  selector: 'app-google-map-multiple-locations',
  templateUrl: './google-map-multiple-locations.component.html',
  styleUrls: ['./google-map-multiple-locations.component.scss']
})
export class GoogleMapMultipleLocationsComponent implements OnInit, OnChanges {
  @Input() locations: any[] = [];
  @Input() center: { lat: number, lng: number } = { lat: 0, lng: 0 };  
  @Input() lat = 39.339832;  
  @Input() lng = -104.4576648;  
  @Input() mapContainerId = 'map-container';  // Add an ID for the map container.

  markers: any[] = [];
  map: any;
  infoWindow: any;

  ngOnInit(): void {
    this.prepareMarkers();
    this.initMap();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['locations'] || changes['center']) {
      this.prepareMarkers();
      if (this.map) {
        this.updateMapCenter();
      }
    }
  }

  // Initialize the Google Map
  initMap(): void {
    const mapOptions = {
      center: new google.maps.LatLng(this.center.lat, this.center.lng),
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    this.map = new google.maps.Map(document.getElementById(this.mapContainerId) as HTMLElement, mapOptions);
    this.infoWindow = new google.maps.InfoWindow();
    // Now that map is initialized, add the markers
    this.prepareMarkers();
  }

  // Prepare the markers for locations
  prepareMarkers(): void {
    this.markers = this.locations
      .filter(location => location.lat && location.lng) // Filter out invalid locations
      .map(location => ({
        lat: location.lat,  
        lng: location.lng,  
        title: location.title,  // Use title from parent component
        info: location.url,
        formattedAddress: location.formattedAddress  // Add the formatted address
      }));
    
    this.addMarkersToMap();
  }  

  // Add markers to the Google Map
  addMarkersToMap(): void {
    this.markers.forEach(markerData => {
      const marker = new google.maps.Marker({
        position: { lat: markerData.lat, lng: markerData.lng },
        map: this.map,
        title: markerData.title,  
      });
  
      // Set the content for the info window on hover (show title and address)
      google.maps.event.addListener(marker, 'mouseover', () => {
        const infoContent = `
          <div class="info-window-title">${markerData.title}</div>
          <div class="info-window-address">${markerData.formattedAddress}</div>
        `;
        this.infoWindow.setContent(infoContent);
        this.infoWindow.open(this.map, marker);  // Open info window on hover
  
        // Apply custom styles to the InfoWindow content dynamically
        const infoWindowElement = document.querySelector('.gm-style-iw');
        if (infoWindowElement) {
          // Cast to HTMLElement for style property access
          const infoWindowHTMLElement = infoWindowElement as HTMLElement;
          
          const titleElement = infoWindowHTMLElement.querySelector('.info-window-title');
          const addressElement = infoWindowHTMLElement.querySelector('.info-window-address');
  
          if (titleElement) {
            const titleHTMLElement = titleElement as HTMLElement;
            titleHTMLElement.style.fontWeight = 'bold';
            titleHTMLElement.style.fontSize = '16px'; // Custom font size
            titleHTMLElement.style.marginBottom = '4px';
          }
  
          if (addressElement) {
            const addressHTMLElement = addressElement as HTMLElement;
            addressHTMLElement.style.fontSize = '14px';
            addressHTMLElement.style.color = '#555';
          }
        }
      });
  
      // Hide the info window when mouseout
      google.maps.event.addListener(marker, 'mouseout', () => {
        this.infoWindow.close();  // Close the info window on mouseout
      });
  
      // Open info window on marker click
      marker.addListener('click', () => {
        this.openInfoWindow(markerData);
      });
    });
  }  

  // Update map center when new location data is provided
  updateMapCenter(): void {
    const { lat, lng } = this.center;
    this.map.setCenter(new google.maps.LatLng(lat, lng));
  }

  // Open an info window on the marker click
  openInfoWindow(markerData: any): void {
    const infoContent = `
      <div class="info-window-title">${markerData.title}</div>
      <div class="info-window-address"><strong>Address:</strong> ${markerData.formattedAddress}</div>
    `;
  
    this.infoWindow.setContent(infoContent);
    this.infoWindow.open(this.map);
  }
}
