import { MouseEvent } from '@agm/core';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/_services/common.service';
import { ProjectService } from 'src/app/_services/project.service';
import { GPS, GPSDetails, Address } from '../../../../interfaces/common-interface';

declare const google;

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnDestroy {
  @Input() showCoordinate = false;
  @Input() isRequired = true;
  @Input() isMap = true;
  @Input() fieldWidth = false;
  @Input() smallSizeField = false;
  @Input() defaultAddress = true;
  @Input() locationData = {
    placeholder: 'Meeting Address',
    name: 'Location',
  }
  zoom = 14;
  inputNumber: number;
  @Input() latitude = this.defaultAddress ? 39.339831974 : 0;
  @Input() longitude = this.defaultAddress ? -104.457664836 : 0;
  private geoCoder = new google.maps.Geocoder;
  @Input() coordinates = [];
  @Input() isEditMode = false; 
  @Output() changeAddress: EventEmitter<GPSDetails> = new EventEmitter<GPSDetails>();
  addressObject;
  centerAddress;
  @ViewChild('map', { static: true }) clickMap: ElementRef;
  getLatLng$: Subscription;
  enterdAddress;
  siteAddress = [];
  @Input() isWaterSource = false;
  @Input() lat: number = this.defaultAddress ? (this.siteAddress.length ? this.siteAddress[1] : 39.339831974) : 0;
  @Input() lng: number = this.defaultAddress ? (this.siteAddress.length ? this.siteAddress[0] : -104.457664836) : 0;
  constructor(
    private fb: UntypedFormBuilder,
    private cos: CommonService,
    private ref: ChangeDetectorRef,
    private projectSrvc: ProjectService,
    private route: ActivatedRoute,
  ) {
  }
  locationForm = this.fb.group({
    _id: [''],
    lat: '',
    lng: '',
    address: '',
    location: this.fb.group({
      streetAddress: [''],
      locality: [''],
      subLocality: [''],
      region: [''],
      country: [''],
      postalCode: [''],
      geo: [''],
      formattedAddress: ['']
    })
  });

  ngOnInit() {
    this.loadAddress();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Handle changes to isWaterSource or isEditMode
    if (changes.isWaterSource || changes.isEditMode) {
      this.loadAddress();
    }
  }

  private loadAddress() {
    if (this.isEditMode) {
      // Load address for Edit mode
      const lat = this.latitude || 39.339831974;
      const lng = this.longitude || -104.457664836;
      this.getLocation(lat, lng);
    } else if (this.isWaterSource) {
      // Keep fields empty for Add mode
      this.locationForm.reset();
      this.lat = 0;
      this.lng = 0;
    }
  }

  getLocation(lat: number, lng: number) {
    this.getAddressByLatLng(lat, lng);
    this.getLatLng$ = this.cos.latLng.subscribe((result) => {
      if (result.length) {
        this.lat = (result[0] === 0) || (result[0] === undefined) ? 39.339831974 : result[0];
        this.lng = (result[1] === 0) || (result[1] === undefined) ? -104.457664836 : result[1];
        this.getAddressByLatLng(this.lat, this.lng);
      }
      else {
        this.cos.getPosition().then(() => {
          this.getAddressByLatLng(this.lat, this.lng);
        });
      }
    });
  }

  mapClicked($event: MouseEvent) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.getAddressByLatLng(this.lat, this.lng);
    this.locationForm.patchValue({
      streetAddress: undefined,
      locality: this.addressObject.locality,
      subLocality: this.addressObject.sub_locality,
      region: this.addressObject.country ? this.addressObject.country : '',
      country: this.addressObject.country,
      postalCode: this.addressObject.object,
      geo: this.addressObject.geo,
      formattedAddress: this.addressObject.formattedAddress,
      lat: this.lat,
      lng: this.lng
    });
  }

  async setAddress(event, type: string) {
    const data = parseFloat(event.target.value);
    
    if (type === 'lat') {
        this.lat = data;
    } else {
        this.lng = data;
    }
    this.ref.detectChanges();
    this.clickMap.nativeElement.click();
    await this.centerChange(event);
    
    const geo: GPS = { longitude: this.lng, latitude: this.lat };
    this.changeAddress.emit({ geo: geo, location: event });
}

  getAddress(event) {
    this.enterdAddress = event;
    this.changeAddress.emit({ geo: event.geo, location: event });
    this.ref.detectChanges();
    this.lat = event.geo[1];
    this.lng = event.geo[0];
    this.locationForm.patchValue({
      location: {
        streetAddress: event.route ? event.route : undefined,
        locality: event.locality ? event.admin_area_l1 : undefined,
        subLocality: event.sub_locality ? event.sub_locality : undefined,
        region: event.country ? event.country : '',
        country: event.country,
        postalCode: event.postal_code ? event.postal_code : undefined,
        geo: event.geo,
        formattedAddress: event.formattedAddress,
      },
      lat: this.lat,
      lng: this.lng
    });
    this.clickMap.nativeElement.click();
  }

  getAddressByLatLng(latitude: number, longitude: number) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK' && results) {
        if (results[0]) {
          this.addressObject = this.getFormattedAddress(results[0]);
          this.locationForm.patchValue({
            address: this.addressObject?.formattedAddress,
            lat: latitude,
            lng: longitude
          });
        } else {
          window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getFormattedAddress(place): Address {
    const locationObj: Address = {
      sub_locality: '',
      locality: '',
      admin_area_l1: '',
      country: '',
      postal_code: '',
      street_number: '',
      route: '',
      formattedAddress: '',
      geo: [0, 0]
    };

    // tslint:disable-next-line: forin
    for (const i in place.address_components) {
      const item = place.address_components[i];

      if (item.types.indexOf('locality') > -1) {
        locationObj.locality = item.long_name;
      } else if (item.types.indexOf('sublocality_level_1') > -1) {
        locationObj.sub_locality = item.long_name;
      } else if (item.types.indexOf('administrative_area_level_1') > -1) {
        locationObj.admin_area_l1 = item.long_name;
      } else if (item.types.indexOf('street_number') > -1) {
        locationObj.street_number = item.short_name;
      } else if (item.types.indexOf('route') > -1) {
        locationObj.route = item.long_name;
      } else if (item.types.indexOf('country') > -1) {
        locationObj.country = item.long_name;
      } else if (item.types.indexOf('postal_code') > -1) {
        locationObj.postal_code = item.short_name;
      }
    }
    locationObj.formattedAddress = place.formatted_address;
    locationObj.geo = [place.geometry.location.lng(), place.geometry.location.lat()];
    return locationObj;
  }

  async centerChange(event) {
    if (!this.enterdAddress) {
      clearTimeout(this.centerAddress);
      this.centerAddress = setTimeout(() => {
        this.getAddressByLatLng(event.lat, event.lng);
        this.locationForm.patchValue({
          streetAddress: undefined,
          locality: this.addressObject?.locality,
          subLocality: this.addressObject?.sub_locality,
          region: this.addressObject?.country ? this.addressObject.country : '',
          country: this.addressObject?.country,
          postalCode: this.addressObject?.object,
          geo: this.addressObject?.geo,
          formattedAddress: this.addressObject?.formattedAddress,
          lat: this.lat,
          lng: this.lng
        });
      }, 1000);
      const geo: GPS = { longitude: this.lng, latitude: this.lat };
      this.changeAddress.emit({ geo: geo, location: event });
    } else {
      this.enterdAddress = '';
    }

  }

  ngOnDestroy(): void {
    if (this.isRequired) {
      this.getLatLng$.unsubscribe();
    }
  }

  validateInput(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode || event.which);
    const pattern = /^[-\d.]$/;
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // This function is used to set the width of fields
  setWidth() {
    return this.fieldWidth ? 'col-4' : (this.smallSizeField ? 'col-3' : 'col-6');
  }

}
