import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { VariableService } from 'src/app/_common/variable.service';
import { CommonService } from 'src/app/_services/common.service';
import { response } from 'src/app/enum/message';

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
})
export class TableComponent implements OnChanges {
  @Input() tableData = [];
  @Input() sortedValues = [];
  @Input() showMap = false;
  @Input() pagination = true;
  @Input() matICon: string;
  @Input() column = [];
  @Input() modal: boolean;
  @Input() isTextColor = false;
  @Input() completeData;
  @Input() showClone = false;
  @Input() isSaved = false;
  @Input() showMerge = false;
  @Input() showInfo = false;
  @Input() showActivity = false;
  @Input() isClickable = false;
  @Input() isSort = false;
  @Input() isStatusToggle = false;
  @Input() disableStatus = false;
  @Input() isStatusApproval = false;
  @Input() showEdit = true;
  @Input() addItem = false;
  @Input() bulkDelete = false;
  @Input() pageSize = 10;
  @Input() isAction = true;
  @Input() showCheck = false;
  @Input() showCheckboxColumn = true;
  @Input() showActionColumn = true;
  @Input() showHeader = true;
  @Input() isDelete = true;
  @Input() actionCellWidth: string;
  @Input() isPagination = true;
  @Input() isDownload = false;
  @Input() isEstimateDoc = false;
  @Input() permission: {
    edit: string;
    remove: string;
    read: string;
    disable?: string;
  };
  @Input() selectionReset = false;
  @Input() isMinWidth = false;
  @Input() isNote = false;
  @Input() showOnlySelectColumn = true;
  @Output() updatedList = new EventEmitter();
  @Output() deleteRecord = new EventEmitter();
  @Output() rightModalData = new EventEmitter();
  @Output() editValues = new EventEmitter();
  @Output() statusToggle = new EventEmitter();
  @Output() otherAction = new EventEmitter();
  @Output() sortedData = new EventEmitter();
  @Output() statusApproval = new EventEmitter();
  @Output() bulkDeleteRecord = new EventEmitter();
  @Output() updateColor = new EventEmitter();

  indexValue: number;
  deletedRecord = [];
  displayedColumns: string[] = ["action"];
  columns: string[];
  sortNameAscd = "#bfbbbb";
  sortNameDscd = "#bfbbbb";
  totalPages = 0;
  currentPage = 1;
  totalRecord = 0;
  showDropdown = false;
  @Input() selection = new SelectionModel(true, []);
  sortingText = [];
  isColorEdit = false;
  checkColor = "brown";
  addTableData = response.en.ADD_TABLE_DATA;
  emptyTableData = response.en.No_DATA_FOUND;
  searchingTableData = response.en.SEARCH_TABLE_DATA;
  // Flag to track if modal is open
  isModalOpen = false;

  constructor(
    public cos: CommonService,
    public commonVariable: VariableService
  ) {}

  ngOnChanges() {
    if (this.selectionReset) {
      this.selection.clear();
      this.deletedRecord = [];
      this.bulkDeleteRecord.emit([]);
    }
    // Initialize displayedColumns based on isAction
    if (this.isAction) {
      this.displayedColumns = this.column.concat(this.displayedColumns);
    } else {
      this.displayedColumns = [...this.column];
    }

    // Add "select" column at the beginning if bulkDelete is enabled
    if (this.bulkDelete) {
      // Remove any existing "select" entries first
      this.displayedColumns = this.displayedColumns.filter(
        (column) => column !== "select"
      );
      // Add "select" at the beginning
      this.displayedColumns.unshift("select");
    }

    // Remove any duplicates
    this.displayedColumns = [...new Set(this.displayedColumns)];

    this.setSortName();
  }

  // Function to set sorting text in header according to type
  setSortName() {
    if (typeof this.indexValue !== "number") {
      this.sortingText = new Array(this.displayedColumns.length).fill("A-Z");
      if (this.displayedColumns.includes("priority")) {
        const index = this.displayedColumns.indexOf("priority");
        this.sortingText[index] = "Z-A";
      }
    }
  }

  // Function used to return true or false if color is to be displayed here
  isColor(data: string, element) {
    if (/^#[0-9A-F]{6}$/i.test(data)) {
      return true;
    } else if (
      element.key === "utilityLocates" &&
      (data === "Expired" || data === "Active")
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Function to show color
  getColor(data: string) {
    if (/^#[0-9A-F]{6}$/i.test(data)) {
      return data;
    } else if (data === "Active") {
      return "green";
    } else {
      return "red";
    }
  }

  // This function is used to check what to display text or icon based on condition
  getApproval(data: string, header: string) {
    if (
      (data && typeof data === "string" && data.includes("COLOR")) ||
      (header && typeof header === "string" && header.includes("link"))
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Function is used to apply check if you want to show some text or not
  getData(data: string, header: string) {
    if (
      data === "PRIMARY_COLOR" ||
      data === "SECONDARY_COLOR" ||
      header === "view_doc/link"
    ) {
      return false;
    } else {
      return true;
    }
  }

  // Function to send the pagination value
  setPage(event) {
    this.selection.clear();
    this.deletedRecord = [];
    this.bulkDeleteRecord.emit([]);
    this.updatedList.emit(event);
  }

  patchData(data, key: string, index: number) {
    if (key === "delete") {
      const deletedData = { ...data, ...{ index } };
      this.deleteRecord.emit(deletedData);
    } else {
      const result = [
        { value: data, key: key, type: this.modal, index: index },
      ];
      this.editValues.emit(result);
    }
  }

  /**
   * Emit action icon click event
   *
   * @param data is the table item data.
   * @param key is the type of the action icon clicked (example: 'info', 'edit', 'delete', 'copy').
   * @param index is the index of the table item.
   */
  actionButton(data, key: string, index: number) {
    const body = { data: data, key: key, index: index };
    this.otherAction.emit(body);
  }

  getValue(data) {
    if (data || data === 0) {
      return data;
    } else {
      return "N/A";
    }
  }

  // Function to handle opening the modal and updating color
  rightModal(element, index: number, record: number) {
    // Check if note is available or if it's clickable and index is 0
    if (this.isNote || (this.isClickable && index === 0)) {
      // Emit the modal data
      this.rightModalData.emit({ value: element, index: record });

      // Set the modal open flag to true to change color
      this.isModalOpen = true;
    } else {
      const data = { value: element, index: record };
      this.isClickable && index === 0 ? this.rightModalData.emit(data) : null;
    }
  }

  // Function to reset modal state if needed
  closeModal() {
    this.isModalOpen = false;
  }

  getColorBasedOnNote(element: any): string {
    if (this.isNote) {
      if (element.read === true) {
        return "black"; // If read is true, color should be black
      } else if (element.read === false) {
        return "var(--primary-color)"; // If read is false, color should be primary color
      }
    }

    // Change color to black if modal is open and the element is clickable
    if (this.isModalOpen && this.isClickable) {
      return "black"; // When modal is open and clickable, color should be black
    }
    return "initial"; // Default color 
  }

  // Function to add color in text if the text is clickable
  getClass(index: number) {
    if (this.isClickable) {
      if (index === 0) return "columnEffect";
      return "";
    }
  }

  updateRecord(element, colorType: string) {
    const result = [{ value: element, key: "MAT_ICON", type: colorType }];
    this.editValues.emit(result);
  }

  // This function is used for sorting the record
  sortName(value: string, type: string, index: number) {
    if (this.isSort) {
      this.indexValue = index;
      let headerIndex: number;

      if (this.displayedColumns.includes("priority")) {
        headerIndex = this.displayedColumns.indexOf("priority");
      }

      // Initialize sorting text with default values
      this.sortingText = new Array(this.displayedColumns.length).fill("A-Z");
      if (headerIndex !== undefined) {
        this.sortingText[headerIndex] = "Z-A";
      }

      // Update sorting text based on the type and order
      if (value === "ascending") {
        if (type === "priority") {
          this.sortingText[this.indexValue] = "Z-A";
        } else if (type === "date") {
          this.sortingText[this.indexValue] = "1-31";
        } else {
          this.sortingText[this.indexValue] = "A-Z";
        }
        this.sortNameAscd = type;
        this.sortNameDscd = "";
      } else {
        if (type === "priority") {
          this.sortingText[this.indexValue] = "A-Z";
        } else if (type === "date") {
          this.sortingText[this.indexValue] = "31-1";
        } else {
          this.sortingText[this.indexValue] = "Z-A";
        }
        this.sortNameDscd = type;
        this.sortNameAscd = "";
      }

      this.sortedData.emit({ value: value, type: type });
    }
  }

  // This function send the the data related to sort button
  sortCheck(data: string) {
    if (this.isSort) {
      if (this.sortedValues.includes(data)) {
        return true;
      } else {
        return false;
      }
    }
  }

  // Function to show only text in table
  dataStyle(data: string, type: string) {
    if (
      this.isStatusToggle ||
      this.isStatusApproval ||
      type === "INSERT-MAT-ICON" ||
      data === "link" ||
      data === "color" ||
      data === "updated"
    ) {
      if (
        data === "status" ||
        data === "visible" ||
        data === "approval" ||
        type === "INSERT-MAT-ICON" ||
        data === "link" ||
        data === "color" ||
        data === "updated"
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  // Function to return boolean if value come in icon
  isIcon(data) {
    if (data === "INSERT-MAT-ICON") {
      return true;
    } else {
      return false;
    }
  }

  // Function is used to add color in text
  textColor(field, text: string) {
    if (this.isTextColor) {
      if (text === "expiry_date") {
        const expiryDate = new Date(field.expiry_date);
        const currentDate = new Date();
        // Reset the time to midnight
        expiryDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        if (expiryDate > currentDate) {
          return "green";
        } else if (expiryDate < currentDate) {
          return "red";
        } else {
          return "green";
        }
      } else {
        if (field["status"] === "Active" && text === "Amount") {
          return "green";
        } else if (field["status"] === "InActive" && text === "Amount") {
          return "red";
        } else {
          return;
        }
      }
    }
  }

  handleToggle(element: any, column: string, event: Event): void {
    if (column === 'visible') {
      this.toggleHandle(element, element[column], event);
    } else {
      this.toggle(element, element[column]);
    }
  }

  toggleHandle(data, item: string, event: Event) {
    // Get the checkbox checked status
    const isChecked = (event.target as HTMLInputElement).checked;

    const result = [{ data: data, column: item, isChecked: isChecked }];
    this.statusToggle.emit(result); 
  }  

  toggle(data, item: string) {
    const result = [{ data: data, item: item }];
    this.statusToggle.emit(result);
  }

  editApproval(data) {
    this.statusApproval.emit(data);
  }

  // function give record while single or whole selection
  isAllSelected(): boolean {
    // Will be updated in future
    // this.bulkDeleteRecord.emit(this.selection.selected);
    return;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.deletedRecord = [];
    this.selection.selected.length
      ? this.selection.clear()
      : this.tableData.forEach((row) => this.selection.select(row));
    this.bulkDeleteRecord.emit(this.selection.selected);
  }

  singleSelection(data, isChecked) {
    if (isChecked) {
      this.deletedRecord.push(data);
    } else {
      if (this.deletedRecord.length) {
        this.deletedRecord = this.cos.removeObjectWithId(
          this.deletedRecord,
          data.id
        );
      } else {
        this.deletedRecord = this.cos.removeObjectWithId(
          this.selection.selected,
          data.id
        );
      }
    }
    this.bulkDeleteRecord.emit(this.deletedRecord);
  }

  // This function is used to give acl permission for particular column and its data
  getPermission(data) {
    if (this.permission.disable) {
      return this.permission.disable.includes(data)
        ? this.permission.edit
        : this.permission.read;
    } else {
      return this.permission.read;
    }
  }

  getActionPermission() {
    if (this.permission.edit) {
      return this.permission.read;
    } else if (this.permission.remove) {
      return this.permission.read;
    } else {
      return this.permission;
    }
  }

  // Function to emit the value to change color
  changeColor(data) {
    this.isColorEdit = data.isDisable ?? false;
    this.updateColor.emit({ details: data });
    setTimeout(() => {
      this.isColorEdit = false;
    }, 10);
  }

  // Function to emit the value to change color
  getColorFromPicker(data: string, element) {
    this.updateColor.emit({ details: element, value: data });
  }
}
