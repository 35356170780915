import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { FileResponse, Message } from "../interfaces/common-interface";
import {
  ProjectList,
  ProjectDetails,
  ProjectClone,
  SummaryDetails,
  RequirementResponse,
  RequirementDetail,
  UnreadNotesCount,
  PdfGenerateResponse,
  DocumentSaveResponse,
  AcknowledgedDocumentsResponse,
  DownloadTailgateDoc,
} from "../interfaces/project";
import {
  Tasks,
  TasksData,
  TaskList,
  TaskListResponse,
  TemplateSave,
  TemplatesCategory,
} from "../interfaces/task-list";
import { ContactList, ContactResponse } from "../interfaces/contact";
import {
  DocumentList,
  DocumentResponse,
  DocumentUpdate,
  NotesList,
  NotesResponse,
} from "../interfaces/upload-document";
import { MeetingList, MeetingResponse } from "../interfaces/meeting";
import {
  LoadListResponse,
  LoadList,
  LoadListCategory,
  LoadItemsResponse,
  LoadItemSubCategory,
  LoadItems,
  TemplateItems,
  TemplateData,
  TemplateList,
  ActivityLog,
} from "../interfaces/load-list";
import {
  HospitalList,
  HospitalDetails,
  MedicalList,
  MedicalResponse,
} from "../interfaces/medical";
import { PerDiemDetails } from "../interfaces/per-diem";

@Injectable({
  providedIn: "root",
})
export class ProjectService {
  PROJECT = "aggregation/project";
  CLONE = "aggregation/clone";
  USERS = "api/user/employee";
  RENTAL = "aggregation/rental";
  TRAVEL = "aggregation/travel";
  CONTACT = "aggregation/contacts";
  NOTE = "aggregation/notes";

  TASK = "aggregation/my-task";
  TASK_LIST = "aggregation/general-tasklist";
  PROJECT_DOCUMENT = "aggregation/project-documents";
  PROJECT_DOCUMENTS = "aggregation/project-documents/multiple";
  DRILL = "aggregation/drill";
  MEETING = "aggregation/meetings";
  SITE_ACTIVITY = "aggregation/site-activity";
  HOLE_COMPLETION = "aggregation/hole-completion/";
  UPLOAD = "upload";
  MISCELLANEOUS = "aggregation/miscellaneous";
  CATEGORY = "aggregation/category";
  SUB_CATEGORY = "aggregation/subCategory";
  CONSUMABLES = "aggregation/consumables/";
  LOAD = "aggregation/loadlist";
  LOAD_CATEGORY = "aggregation/loadlist-category/list";
  LOAD_SUBCATEGORY = "aggregation/loadlist-category";
  LOAD_ITEM_DETAILS = "aggregation/loadlist/item";
  ADD_LOAD_ITEM = "aggregation/loadlist";
  GET_TEMPLATE_ITEMS = "aggregation/loadlist-template";
  GET_TEMPLATE = "aggregation/loadlist-template/list";
  TEMPLATE_ITEM_UPDATE = "aggregation/loadlist-template/item";
  GOOGLE_MAP = "aggregation/google-apis/find-place-by-name";
  PDF = "aggregation/report/loadlist";
  LOCATION_BY_NAME = "aggregation/google-apis/find-place-by-name";
  LOCATION_BY_COORD = "aggregation/google-apis/find-near-by";
  LOCATION_NAME = "aggregation/google-apis/find-by-place-id";
  LOCATION_DETAILS = "aggregation/google-apis/navigation";
  CRUD_LOCATION = "aggregation/project/medical";
  MEDICAL_LIST = "aggregation/project/medical/list";
  TASK_DETAILS = "aggregation/my-task/list";
  TASK_DETAIL = "aggregation/my-task";
  DOCUMENT = "project-documents";
  ACTIVITY_LOG = "aggregation/activity-logs";
  TASK_TEMPLATE = "aggregation/task-list-template/list";
  TEMPLATE_DATA = "aggregation/task-list-template";
  WATER_URL_LIST = "aggregation/water-source/list";
  URL_CRUD = "aggregation/water-source";
  SUMMARY_DATA = "aggregation/project-summary";
  READ_NOTES = "aggregation/notes/read-notes";
  REQUIREMENT = "aggregation/project-requirement";
  UNREAD_COUNT = "aggregation/notes/unread-notes-count";
  GENERATE_DOCUMENT = "aggregation/generate-project-doc";
  TAILGATE_DOC = "aggregation/tailgate-doc";

  constructor(private http: HttpClient) {}
  reset = false;
  displayModal = "OPEN";

  private emitChangeSource = new BehaviorSubject<boolean>(this.reset);
  changeEmitted$ = this.emitChangeSource.asObservable();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private emitdisplayColumn = new BehaviorSubject<any>(this.displayModal);
  displayColumn$ = this.emitdisplayColumn.asObservable();

  private summaryDetail = new BehaviorSubject([]);
  summaryInfo$ = this.summaryDetail.asObservable();

  emitChange(change) {
    this.emitChangeSource.next(change);
    this.emitdisplayColumn.next(change);
    this.summaryDetail.next(change);
  }

  /**
   * Project Services
   */
  getProjectsMapping(search) {
    return this.http.get<ProjectList>(
      `${environment.BASE_URL}${this.PROJECT}?pagination=${
        search.pagination
      }&page=${search.pageNumber}&limit=${
        search.noOfRecord
      }&search=${search.search.trim()}&projectSort=${
        search.projectSort
      }&clientSort=${search.clientSort}&jobNameSort=${
        search.jobNameSort
      }&status=${JSON.stringify(search.status)}`
    );
  }

  getActivities(data) {
    return this.http.get<ProjectList>(
      `${environment.BASE_URL}${this.ACTIVITY_LOG}?id=${data.id}&type=${data.type}`
    );
  }

  getProjects() {
    return this.http.get<ProjectList>(
      `${environment.BASE_URL}${this.PROJECT}?pagination=false`
    );
  }

  getProject(id) {
    return this.http.get<ProjectDetails>(
      `${environment.BASE_URL}${this.PROJECT}/${id}`
    );
  }

  getProjectDetails(id) {
    return this.http.get<ProjectDetails>(
      `${environment.BASE_URL}${this.PROJECT}/${id}`
    );
  }

  deleteProjects(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };
    return this.http.delete<Message>(
      environment.BASE_URL + this.PROJECT,
      options
    );
  }

  addProject(data) {
    return this.http.post<ProjectDetails>(
      environment.BASE_URL + this.PROJECT,
      data
    );
  }

  updateProject(data) {
    return this.http.put<ProjectDetails>(
      environment.BASE_URL + this.PROJECT,
      data
    );
  }

  cloneProject(data) {
    return this.http.post<ProjectClone>(
      environment.BASE_URL + this.CLONE,
      data
    );
  }

  /**
   * API services for project requirement
   */

  summaryData(id: string) {
    return this.http.get<SummaryDetails>(
      `${environment.BASE_URL}${this.SUMMARY_DATA}?id=${id}`
    );
  }

  addSummary(data) {
    return this.http.post<SummaryDetails>(
      environment.BASE_URL + this.SUMMARY_DATA,
      data
    );
  }

  updateSummary(data) {
    return this.http.put<SummaryDetails>(
      environment.BASE_URL + this.SUMMARY_DATA,
      data
    );
  }


  /**
   * API services for project requirement
   */

  getRequirementDetails(id: string) {
    return this.http.get<RequirementDetail>(
      `${environment.BASE_URL}${this.REQUIREMENT}?id=${id}`
    );
  }

  addRequirement(data) {
    return this.http.post<RequirementResponse>(
      environment.BASE_URL + this.REQUIREMENT,
      data
    );
  }

  updateRequirement(data) {
    return this.http.put<RequirementResponse>(
      environment.BASE_URL + this.REQUIREMENT,
      data
    );
  }
  /**
   * Task Services
   */
  getTask(search) {
    return this.http.get<Tasks>(
      `${environment.BASE_URL}${this.TASK}?pagination=${search.pagination}&projectId=${search.projectId}&prioritySort=${search.prioritySort}&search=${search.search}&limit=${search.noOfRecord}&page=${search.pageNumber}&status=${JSON.stringify(search.status)}&priority=${JSON.stringify(search.priority)}
      &startDate=${search.startDate}&endDate=${search.endDate}`);
  }

  addTask(data, query: boolean) {
    return this.http.post<TasksData>(
      `${environment.BASE_URL}${this.TASK}?isTemplate=${query}`,
      data
    );
  }

  /**
   * API Request to update Task Details
   *
   * @param data Task Data Object to Send in API Request Payload
   * @param id Task ID
   */
  updateTask(data, id?:string) {
    return this.http.put<TasksData>(`${environment.BASE_URL}${this.TASK}?id=${id}`, data);
  }

  /**
   * API Request to delete Tasks
   * @param data Request payload to delete tasks
   */
  deleteTask(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };

    return this.http.delete<Message>(environment.BASE_URL + this.TASK, options);
  }

  getTaskDetails(data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(
      `${environment.BASE_URL}${this.TASK_DETAILS}?tasklist=${data}`
    );
  }

  /**
   * API Request to get task detail
   *
   * @param id Task ID
   */
  getTaskDetail(id:string) {
    return this.http.get<any>(
      `${environment.BASE_URL}${this.TASK_DETAIL}?id=${id}`
    );
  }

  /**
   * TaskTemplate Service
   */
  updateTemplateInfo(data) {
    return this.http.put<TemplateSave>(
      environment.BASE_URL + this.TEMPLATE_DATA,
      data
    );
  }

  saveAsTemplate(data) {
    return this.http.post<TemplateSave>(
      environment.BASE_URL + this.TEMPLATE_DATA,
      data
    );
  }

  deleteTemplateData(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.TEMPLATE_DATA, options);
  }

  getTemplateTask(search) {
    return this.http.get<Tasks>(
      `${environment.BASE_URL}${this.TASK}?pagination=${search.pagination}&projectId=${search.projectId}&search=${search.search}&limit=${search.noOfRecord}&page=${search.pageNumber}`
    );
  }

  /**
   * Documents Services
   */

  getProjectDocument(search) {
    return this.http.get<DocumentList>(
      `${environment.BASE_URL}${this.PROJECT_DOCUMENT}?pagination=${search.pagination}&limit=${search.noOfRecord}&projectId=${search.projectId}&search=${search.search}&page=${search.pageNumber}&type=${search.type}`
    );
  }

  addMultipleDocument(data) {
    return this.http.post<DocumentResponse>(
      environment.BASE_URL + this.PROJECT_DOCUMENTS,
      { documents: data }
    );
  }

  updateProjectDocument(data) {
    return this.http.put<DocumentUpdate>(
      environment.BASE_URL + this.PROJECT_DOCUMENT,
      data
    );
  }

  deleteProjectDocument(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };

    return this.http.delete<Message>(
      environment.BASE_URL + this.PROJECT_DOCUMENT,
      options
    );
  }

  /**
   * Task List Services
   */
  getTaskList(search) {
    return this.http.get<TaskList>(
      `${environment.BASE_URL}${this.TASK_LIST}?pagination=${search.pagination}&limit=${search.noOfRecord}&projectId=${search.projectId}&search=${search.search}&page=${search.pageNumber}`
    );
  }

  getTaskLists(search) {
    return this.http.get<TaskList>(
      `${environment.BASE_URL}${this.TASK_LIST}?pagination=${search.pagination}&projectId=${search.projectId}`
    );
  }

  addTaskList(data) {
    return this.http.post<TaskListResponse>(
      environment.BASE_URL + this.TASK_LIST,
      data
    );
  }

  updateTaskList(data) {
    return this.http.put<TaskListResponse>(
      environment.BASE_URL + this.TASK_LIST,
      data
    );
  }

  deleteTaskList(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };

    return this.http.delete<Message>(
      environment.BASE_URL + this.TASK_LIST,
      options
    );
  }

  getTaskTemplate(data) {
    return this.http.get<TemplatesCategory>(
      `${environment.BASE_URL}${this.TASK_TEMPLATE}?pagination=${data.pagination}&search=${data.search}&page=${data.pageNumber}&limit=${data.noOfRecord}`
    );
  }

  getTemplatesDetail(id: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(
      `${environment.BASE_URL}${this.TASK_TEMPLATE}?templateCategory=${id}`
    );
  }

  /**
   *  Send email to logged in user with tasklist report.
   */
  public sendReportToUser(data) {
    return this.http.post<Message>(
      `${environment.BASE_URL}${this.TASK_LIST}/generate-pdf`,
      data
    );
  }

  /**
   *  Send email to logged in user with tasklist report.
   */
  public downloadReport(data) {
    return this.http.post<FileResponse>(
      `${environment.BASE_URL}${this.TASK_LIST}/view-report`,
      data
    );
  }

  /**
   * Contact Services
   */
  getContacts(search) {
    return this.http.get<ContactList>(
      `${environment.BASE_URL}${this.CONTACT}?pagination=${search.pagination}&limit=${search.noOfRecord}&projectId=${search.projectId}&search=${search.search}&page=${search.pageNumber}`
    );
  }

  allContacts(projectId: string) {
    return this.http.get<ContactList>(
      `${environment.BASE_URL}${this.CONTACT}?pagination=false&projectId=${projectId}`
    );
  }

  addContact(data) {
    return this.http.post<ContactResponse>(
      environment.BASE_URL + this.CONTACT,
      data
    );
  }
  updateContact(data) {
    return this.http.put<ContactResponse>(
      environment.BASE_URL + this.CONTACT,
      data
    );
  }

  deleteContact(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };

    return this.http.delete<Message>(
      environment.BASE_URL + this.CONTACT,
      options
    );
  }

  /**
   * Notes Services
   */
  getNote(search) {
    return this.http.get<NotesList>(
      `${environment.BASE_URL}${this.NOTE}?pagination=${search.pagination}&limit=${search.noOfRecord}&projectId=${search.projectId}&search=${search.search}&page=${search.pageNumber}`
    );
  }

  addNote(data) {
    return this.http.post<NotesResponse>(
      environment.BASE_URL + this.NOTE,
      data
    );
  }

  updateNote(data) {
    return this.http.put<NotesResponse>(environment.BASE_URL + this.NOTE, data);
  }

  deleteNote(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };

    return this.http.delete<Message>(environment.BASE_URL + this.NOTE, options);
  }

  /**
   * Meeting Services
   */
  getMeeting(search) {
    return this.http.get<MeetingList>(
      `${environment.BASE_URL}${this.MEETING}?date=${search.date}&projectId=${search.projectId}`
    );
  }

  getAllMeeting(search) {
    return this.http.get<MeetingList>(
      `${environment.BASE_URL}${this.MEETING}/list?date=${search.date}&projectId=${search.projectId}&pagination=${search.pagination}&page=${search.pageNumber}&limit=${search.noOfRecord}`
    );
  }

  addMeeting(data) {
    return this.http.post<MeetingResponse>(
      environment.BASE_URL + this.MEETING,
      data
    );
  }

  updateMeeting(data) {
    return this.http.put<MeetingResponse>(
      environment.BASE_URL + this.MEETING,
      data
    );
  }

  deleteMeeting(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };

    return this.http.delete<Message>(
      environment.BASE_URL + this.MEETING,
      options
    );
  }

  /**
   * LoadList Services
   */
  addLoadDocument(data) {
    return this.http.post<LoadListResponse>(
      environment.BASE_URL + this.LOAD,
      data
    );
  }

  getLoadListData(data) {
    return this.http.get<LoadList>(
      `${environment.BASE_URL}${this.LOAD}?pagination=${data.pagination}&limit=${data.noOfRecord}&page=${data.pageNumber}&search=${data.search}&projectId=${data.projectId}`
    );
  }

  getItemCategory() {
    return this.http.get<LoadListCategory>(
      environment.BASE_URL + this.LOAD_CATEGORY
    );
  }

  updateLoadList(data) {
    return this.http.put<TemplateItems>(environment.BASE_URL + this.LOAD, data);
  }

  deleteLoadList(data) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    };
    return this.http.delete<Message>(environment.BASE_URL + this.LOAD, options);
  }

  /**
   * Load List's Items Services
   */
  generatePDF(data) {
    return this.http.get<Message>(
      `${environment.BASE_URL}${this.PDF}?loadlistId=${data.loadlistId}`
    );
  }

  generateXLS(data) {
    return this.http.get<Message>(
      `${environment.BASE_URL}${this.PDF}?xls=${data.xls}&loadlistId=${data.loadlistId}`
    );
  }

  getItemSubCategory(data) {
    return this.http.get<LoadItemSubCategory>(
      `${environment.BASE_URL}${this.LOAD_SUBCATEGORY}?parentId=${data}`
    );
  }

  addLoadItem(data) {
    return this.http.put<LoadItemsResponse>(
      environment.BASE_URL + this.ADD_LOAD_ITEM,
      data
    );
  }

  editItemDetails(data) {
    return this.http.put<LoadItemsResponse>(
      environment.BASE_URL + this.LOAD_ITEM_DETAILS,
      data
    );
  }

  getItemDetails(data) {
    return this.http.post<LoadItems>(
      environment.BASE_URL + this.LOAD_ITEM_DETAILS,
      data
    );
  }

  getTemplateData(data) {
    return this.http.get<TemplateData>(
      `${environment.BASE_URL}${this.GET_TEMPLATE_ITEMS}?templateCategory=${data}`
    );
  }

  getTemplateItems(data) {
    return this.http.get<TemplateItems>(
      `${environment.BASE_URL}${this.GET_TEMPLATE_ITEMS}?templateId=${data}`
    );
  }

  getTemplateList(data) {
    return this.http.get<TemplateList>(
      `${environment.BASE_URL}${this.GET_TEMPLATE}?pagination=${data.pagination}&limit=${data.noOfRecord}&page=${data.pageNumber}&search=${data.search}`
    );
  }

  templateDelete(data) {
    return this.http.delete<Message>(
      `${environment.BASE_URL}${this.GET_TEMPLATE_ITEMS}?templateId=${data}`
    );
  }

  addTemplateData(data) {
    return this.http.post<TemplateItems>(
      environment.BASE_URL + this.GET_TEMPLATE_ITEMS,
      data
    );
  }

  itemDelete(data) {
    return this.http.delete<Message>(
      `${environment.BASE_URL}${this.LOAD}?loadlistId=${data.loadlistId}&itemId=${data.itemId}`
    );
  }

  getTemaplateDetails(data) {
    return this.http.get<TemplateItems>(
      `${environment.BASE_URL}${this.GET_TEMPLATE_ITEMS}?templateId=${data}`
    );
  }
  updateTemplateData(data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.put<any>(
      environment.BASE_URL + this.GET_TEMPLATE_ITEMS,
      data
    );
  }

  editItemDocument(data) {
    return this.http.put<TemplateItems>(environment.BASE_URL + this.LOAD, data);
  }

  activityLog(data) {
    return this.http.get<ActivityLog>(
      `${environment.BASE_URL}${this.LOAD_ITEM_DETAILS}?itemId=${data}`
    );
  }

  templateItemDelete(data) {
    return this.http.delete<Message>(
      `${environment.BASE_URL}${this.GET_TEMPLATE_ITEMS}?templateId=${data.templateId}&itemId=${data.itemId}`
    );
  }

  templateItemUpdate(data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.put<any>(
      environment.BASE_URL + this.TEMPLATE_ITEM_UPDATE,
      data
    );
  }

  public downloadLoadlist(data) {
    return this.http.post<FileResponse>(`${environment.BASE_URL + this.LOAD}/view-report`, data);
  }


  /**
   * Medical Services
   */
  getLocationCoord(data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(
      `${environment.BASE_URL}${this.LOCATION_BY_NAME}?placeName=${data}`
    );
  }

  getLocationPlace(data) {
    return this.http.get<HospitalList>(
      `${environment.BASE_URL}${this.LOCATION_BY_COORD}?latitude=${data.lat}&longitude=${data.lang}`
    );
  }

  getPlaceName(data) {
    return this.http.get<HospitalDetails>(
      `${environment.BASE_URL}${this.LOCATION_NAME}?placeId=${data}`
    );
  }

  getPlaceDetails(data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.get<any>(
      `${environment.BASE_URL}${this.LOCATION_DETAILS}?sourceLatitude=${data.sourceLatitude}&sourceLongitude=${data.sourceLongitude}&destinationLatitude=${data.destinationLatitude}&destinationLongitude=${data.destinationLongitude}`
    );
  }

  createLocation(data) {
    return this.http.post<HospitalDetails>(
      environment.BASE_URL + this.CRUD_LOCATION,
      data
    );
  }

  getMedicalList(data) {
    return this.http.get<MedicalList>(
      `${environment.BASE_URL}${this.MEDICAL_LIST}?pagination=${data.pagination}&search=${data.search}&page=${data.pageNumber}&limit=${data.noOfRecord}&projectId=${data.projectId}`
    );
  }

  allMedicalLocation(id :string) {
    return this.http.get<MedicalList>(
      `${environment.BASE_URL}${this.MEDICAL_LIST}?pagination=false&projectId=${id}`
    );
  }

  medicalLocationDetails(id :string) {
    return this.http.get<MedicalResponse>(
      `${environment.BASE_URL}${this.CRUD_LOCATION}?medicalId=${id}`
    );
  }
  deleteMedicalLocation(id) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: id
    };

    return this.http.delete<Message>
      (environment.BASE_URL + this.CRUD_LOCATION,options);
  }

  updateLocationList(data) {
    return this.http.put<MedicalResponse>(
      environment.BASE_URL + this.CRUD_LOCATION,
      data
    );
  }

  /**
   * water source Urls
   */
  getWaterSourceURL(data) {
    return this.http.get<DocumentList>(
      `${environment.BASE_URL}${this.WATER_URL_LIST}?pagination=${data.pagination}&search=${data.search}&page=${data.pageNumber}&limit=${data.noOfRecord}&project=${data.projectId}`
    );
  }

  getWaterSourceURLs(data) {
    return this.http.get<DocumentList>(
      `${environment.BASE_URL}${this.WATER_URL_LIST}?&project=${data}`
    );
  }

  addUrl(data) {
    return this.http.post<DocumentResponse>(
      environment.BASE_URL + this.URL_CRUD,
      data
    );
  }

  updateUrl(data) {
    return this.http.put<DocumentUpdate>(
      environment.BASE_URL + this.URL_CRUD,
      data
    );
  }

  deleteUrl(data) {
    return this.http.delete<Message>(
      `${environment.BASE_URL}${this.URL_CRUD}?id=${data}`
    );
  }

  // Notes read and unread CRUD

  updateNotes(data) {
    return this.http.put<DocumentUpdate>(
      environment.BASE_URL + this.READ_NOTES,
      data
    );
  }

  getUnreadCount(projectId) {
    return this.http.get<UnreadNotesCount>(
      `${environment.BASE_URL}${this.UNREAD_COUNT}?projectId=${projectId}`
    );
  }
  generateTailgateDocument(data) {
    return this.http.post<PdfGenerateResponse>(
       `${environment.BASE_URL}${this.GENERATE_DOCUMENT}/get-tailgate-doc?pagination=${data.pagination}&search=${data.search}&page=${data.pageNumber}&limit=${data.noOfRecord}`,
      data
    );
  }
  
  saveTailgateDocument(data) {
    return this.http.post<DocumentSaveResponse>(
       environment.BASE_URL + this.TAILGATE_DOC,
      data
    );
  }

  getAcknowledgeTailgateDoc(data) {
    return this.http.get<AcknowledgedDocumentsResponse>(
      `${environment.BASE_URL}${this.TAILGATE_DOC}/list?projectId=${data.projectId}&pagination=${data.pagination}&search=${data.search}&page=${data.pageNumber}&limit=${data.noOfRecord}`
    );
  }  

  updateAcknowledgeTailgateDoc(data) {
    return this.http.put<AcknowledgedDocumentsResponse>(
       `${environment.BASE_URL}${this.TAILGATE_DOC}/acknowledge`,
      data
    );
  }
  
  downloadTailgateDocument(data) {
    return this.http.post<DownloadTailgateDoc>(
      `${environment.BASE_URL}${this.TAILGATE_DOC}/download-doc`,
      data
    );
  }

}
