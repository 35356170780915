import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonService } from 'src/app/_services/common.service';
import { Form, Assignment } from '../../../../../interfaces/common-interface';
@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnChanges {
  @Input() dropDownData: Assignment;
  @Input() formData: Form;
  @Input() patchData;
  @Output() dropDownIds = new EventEmitter();
  count = 0;
  originalData = [];
  searchRecord: string;
  isUserInput = false;
  selectedIds = [];
  constructor(public cos: CommonService) { }

  ngOnChanges() {
    if (!Array.isArray(this.patchData) && this.patchData) {
      if (this.patchData.ids)
        this.formData.patchValue({
          [this.patchData.name]: this.patchData.ids
        });
    }
  }

  searchRecordName(data: string, fieldName: string, tableData) {
    if (this.count === 0) {
      this.originalData = tableData;
    }
    this.count++;
    if (data) {
      const filteredData = this.originalData.filter((item) =>
        item.value.toLowerCase().includes(data.toLowerCase())
      );
      if (this.dropDownData.name === fieldName) {
        this.dropDownData.multipleValues = filteredData;
      }
    } else {
      if (this.dropDownData.name === fieldName) {
        this.dropDownData.multipleValues = this.originalData;
      }
    }
  }

  triggerEvent(data: boolean, fieldName: string, arr) {
    this.count = 0;
    if (!data) {
      if (this.dropDownData.name === fieldName) {
        this.dropDownData.multipleValues = this.originalData.length ? this.originalData : arr;
      }
      this.searchRecord = '';
    }
  }

  patchSelectedValue(selectionState: boolean, id: string, fieldName: string, singleSelection, value: string) {
    if (id) {
      this.selectedIds.push(id);
      this.selectedIds = this.selectedIds.flat();
      this.selectedIds = this.cos.removeDuplicates(this.selectedIds);
      if (this.selectedIds.length === 0) {
        this.selectedIds = [];
      }
      this.dropDownIds.emit({
        name: fieldName,
        ids: singleSelection ? id : this.selectedIds,
        assignId: id,
        state: selectionState,
        dropDownData: this.dropDownData,
        value: value });
    }
  }

}
